import "./assets/styles/App.css";
import { Routes, Route } from "react-router-dom";

// Image imports
// import { bg } from "./assets/images/bg.svg";

// Layouts
import Header from "./components/Layout/Header";
// import Newsletter from "./components/Common/Newsletter";
import Footer from "./components/Layout/Footer";

// Pages
import Home from "./pages/Home/Home.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import Services from "./pages/Services/Services.js";
import Staffing from "./pages/Staffing/Staffing.js";
import Consultation from "./pages/Consultation/Consultation.js";
// import Blog from "./pages/Blog/Blog.js";
import Careers from "./pages/Careers/Careers.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
import Resources from "./pages/Resources/Resources.js";
import TandC from "./pages/TandC/TandC.js";

export default function App() {
  return (
    <div className="App">
      <div className="container-fluid p-0">
        <Header />
        <main
          // style={{ backgroundImage: `url(${bg})` }}
          className="App-main container-fluid px-0"
        >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/staffing" element={<Staffing />} />
            <Route exact path="/consultation" element={<Consultation />} />
            <Route exact path="/careers" element={<Careers />} />
            <Route exact path="/contact-Us" element={<ContactUs />} />
            <Route exact path="/resources" element={<Resources />} />
            <Route exact path="/terms-and-conditions" element={<TandC />} />
          </Routes>
        </main>

        {/* <Newsletter /> */}
        <Footer />
      </div>
    </div>
  );
}
