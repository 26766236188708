import "./Careers.css";

export default function Careers() {
  return (
    <div className="container careers py-5">
      <h1 className="fw-bold">Careers</h1>
      <p>
        We are searching for dedicated, mission-driven individuals who want to
        serve our patients with dignity and respect while also assisting them in
        achieving long-term independence.
      </p>
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-12 col-md-3 mb-3 mx-3">
          <div className="card text-center mx-auto p-2 mb-3">
            <div className="card-body">
              <i aria-hidden="true" className="fas fa-clipboard-list"></i>{" "}
              <h2 className="card-title fw-bold py-4">RN/LPN</h2>
              <a
                href="http://supremehealthservicesllc.com/blog/index.php/rn-lpn/"
                className="btn career-btn btn-lg mb-3 fw-bold"
              >
                ONLINE FORM
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3 mx-3">
          <div className="card text-center mx-auto p-2 mb-3">
            <div className="card-body">
              <i aria-hidden="true" className="fas fa-hands"></i>{" "}
              <h2 className="card-title fw-bold py-4">Caregiver</h2>
              <a
                href="http://supremehealthservicesllc.com/blog/index.php/caregiver/"
                className="btn career-btn btn-lg mb-3 fw-bold"
              >
                ONLINE FORM
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3 mx-3">
          <div className="card text-center mx-auto p-2 mb-3">
            <div className="card-body">
              <i aria-hidden="true" className="fas fa-file-alt"></i>{" "}
              <h2 className="card-title fw-bold py-4">CNA</h2>
              <a
                href="http://supremehealthservicesllc.com/blog/index.php/cna/"
                className="btn career-btn btn-lg mb-3 fw-bold"
              >
                ONLINE FORM
              </a>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-md-3 mb-3 mx-3">
          <div className="card text-center mx-auto p-2 mb-3">
            <div className="card-body">
              <i aria-hidden="true" className="fas fa-user-nurse"></i>{" "}
              <h2 className="card-title fw-bold py-4">RN</h2>
              <a
                href="http://supremehealthservicesllc.com/blog/index.php/rn/"
                className="btn career-btn btn-lg mb-3 fw-bold"
              >
                ONLINE FORM
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
